<template>
    <div class="flex-row align-items-center">
        <CContainer>
            <CRow class="justify-content-center">
                <CCol md="8">
                    <CCardGroup>
                        <CCard class="p-4">
                            <CCardBody>
                                <CForm>
                                    <CRow class="mb-3">
                                        <CCol col="9" class="text-left">
                                            <h2><b>Lupa Password</b></h2>
                                            <p class="text-muted">Masukkan email anda untuk reset password anda</p>
                                        </CCol>
                                        <CCol col="3" class="text-left">
                                            <img src="img/login.png" alt="" class="img-responsive" style="width: 75px; float: right;">
                                        </CCol>
                                    </CRow>
                                    <CInput placeholder="Email" id="email" type="email">
                                        <template #prepend-content><CIcon name="cil-user" /></template>
                                    </CInput>
                                    <CRow>
                                        <CCol col="12" class="text-left">
                                            <CButton class="btn btn-primary btn-block" v-on:click="sendEmailProcess">Kirim</CButton>
                                        </CCol>
                                        <!-- <CCol col="6" class="text-right">
                                            <router-link :to="{path: 'login'}" color="link" class="px-0" >Login</router-link>
                                        </CCol> -->
                                    </CRow>
                                </CForm>
                            </CCardBody>
                        </CCard>
                        
                    </CCardGroup>
                </CCol>
            </CRow>
        </CContainer>
    </div>
</template>
<script>
    export default {
        name: "LupaPassword",

        methods: {
            sendEmailProcess: function () {
                let self = this;
                Swal.fire({
                    title: '<i class="fa fa-refresh fa-spin"></i>',
                    text: "Loading...",
                    showConfirmButton: false,
                });
                axios
                .post(this.apiLink + "api/forgot-password", {
                    email: $("#email").val()
                })
                .then((response) => {
                    let timerInterval;
                    Swal.fire({
                        icon: "success",
                        title: "Data Ditemukan",
                        text: "Anda akan diarahkan ke halaman reset password",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        didOpen: () => {
                            timerInterval = setInterval(() => {
                                const content = Swal.getContent();
                                if (content) {
                                    const b = content.querySelector("b");
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft();
                                    }
                                }
                            }, 100);
                        },
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            self.$router.push({ name: 'ResetPassword', query: {email: $("#email").val()}});
                            // self.$router.push({ name: 'Login'});
                        }
                    });
                    // alert(this.access_token);
                    setTimeout(this.redirectDashboard, 1500);
                }).catch((error) => {
                    if (error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Gagal',
                            text: "Email tidak ditemukan, silahkan melakukan registrasi",
                            showConfirmButton: false,
                        });
                    }
                });
            },
        },
    };
</script>